
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as index4mOSjbOKtpMeta } from "/builds/curs3_w4ll/portfolio/pages/index.vue?macro=true";
import { default as projectsy5XL0ZfZsbMeta } from "/builds/curs3_w4ll/portfolio/pages/projects.vue?macro=true";
import { default as component_45stubCb4MuJOGIkMeta } from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubCb4MuJOGIk } from "/builds/curs3_w4ll/portfolio/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "index___en",
    path: "/",
    component: () => import("/builds/curs3_w4ll/portfolio/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/builds/curs3_w4ll/portfolio/pages/index.vue")
  },
  {
    name: "projects___en",
    path: "/projects",
    component: () => import("/builds/curs3_w4ll/portfolio/pages/projects.vue")
  },
  {
    name: "projects___fr",
    path: "/fr/projets",
    component: () => import("/builds/curs3_w4ll/portfolio/pages/projects.vue")
  },
  {
    name: component_45stubCb4MuJOGIkMeta?.name,
    path: "/sitemap.xml",
    component: component_45stubCb4MuJOGIk
  }
]