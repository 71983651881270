import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAbout, LazyExperience, LazyProfile, LazyProject, LazyProjectsPreview } from '#components'
const lazyGlobalComponents = [
  ["About", LazyAbout],
["Experience", LazyExperience],
["Profile", LazyProfile],
["Project", LazyProject],
["ProjectsPreview", LazyProjectsPreview],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
